import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import MetaData from "../../components/meta"
import Spinner from "../../components/Spinner"

// Styles
import "../../styles/traefik-ambassador-program-apply.scss"

const AmbassadorApplyPage = ({ data, location }) => {
  const [formStep, setFormStep] = useState(0)
  const [formFields, updateFormFields] = useState([])
  const [hasFormSubmitted, setHasFormSubmitted] = useState(false)

  useEffect(() => {
    if (typeof hbspt == "undefined") {
      let script = document.createElement("script")
      script.src = "//js.hsforms.net/forms/v2.js"
      script.onload = function () {
        createHubspotForm()
      }
      document.getElementsByTagName("head")[0].appendChild(script)
    } else {
      createHubspotForm()
    }
  }, [])

  const createHubspotForm = () => {
    // Hubspot APIs callback function - onFormReady (used below) - requires jQuery.
    // Without jQuery it won't execute. This little trick avoids us having to load jQuery.
    window.jQuery =
      window.jQuery ||
      (() => ({
        // these are all methods required by HubSpot
        change: () => {},
        trigger: () => {},
      }))

    hbspt.forms.create({
      portalId: "5814022",
      formId: "ab6c6ce7-db58-4ae4-aae8-9f2de46d4d2e",
      target: "#ambassador-apply-form",
      onFormReady: function () {
        let rows = Array.from(
          document.querySelectorAll("#ambassador-apply-form form > fieldset")
        )
        let fields = []
        fields.push(rows.splice(0, 6))
        fields.push(rows.splice(0, 11))
        fields.push(rows)
        updateFormFields(fields)
        fields[formStep].forEach(row => {
          row.style.display = "block"
        })
      },
      onFormSubmitted: function () {
        setHasFormSubmitted(true)
      },
    })
  }

  const goToFormStep = (i: number) => {
    formFields[formStep].forEach(fieldset => {
      fieldset.style.display = "none"
    })
    formFields[i].forEach(fieldset => {
      fieldset.style.display = "block"
    })
    setFormStep(i)
    if (i == 2) {
      document
        .querySelector("#ambassador-apply-form")
        ?.classList.add("last-step")
    } else {
      document
        .querySelector("#ambassador-apply-form")
        ?.classList.remove("last-step")
    }
  }

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />
      <div className="ambassador-apply-page">
        {/* Header */}
        <div className="ambassador-apply-header page-header">
          <div className="wrapper-1200">
            <h1>Apply to Become a Traefik Ambassador</h1>
          </div>
        </div>

        {/* Form */}
        <div className="ambassador-form page-content-section">
          <div className="wrapper-600">
            {!hasFormSubmitted && (
              <div className="ambassador-form-nav">
                <button
                  className={
                    `button--knocked-out--round ` +
                    (formStep == 0 ? `button--knocked-out--round--active` : ``)
                  }
                  onClick={() => goToFormStep(0)}
                >
                  Your Information
                </button>
                <button
                  className={
                    `button--knocked-out--round ` +
                    (formStep == 1 ? `button--knocked-out--round--active` : ``)
                  }
                  onClick={() => goToFormStep(1)}
                >
                  More about you
                </button>
                <button
                  className={
                    `button--knocked-out--round ` +
                    (formStep == 2 ? `button--knocked-out--round--active` : ``)
                  }
                  onClick={() => goToFormStep(2)}
                >
                  Your contribution &amp; application
                </button>
              </div>
            )}

            <div
              className="ambassador-form-main hubspot-form-wrapper"
              id="ambassador-apply-form"
            >
              <Spinner />
            </div>

            <div className="ambassador-form-footer">
              <div>
                {(formStep == 1 || formStep == 2) && (
                  <button
                    className="button--primary button--extra-padding button--with-shadow"
                    onClick={() => goToFormStep(formStep - 1)}
                  >
                    Prev
                  </button>
                )}
              </div>
              <div>
                {(formStep == 0 || formStep == 1) && (
                  <button
                    className="button--primary button--extra-padding button--with-shadow"
                    onClick={() => goToFormStep(formStep + 1)}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    current_page: ghostPage(slug: { eq: "traefik-ambassador-program" }) {
      ...PageMetaFields
    }
  }
`

export default AmbassadorApplyPage
